// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: 'http://192.168.1.163:3001', // API LocalHost
  // url: 'http://localhost:3001', // API LocalHost
  url: 'https://api.dev.viuhealth.com',
  socket: 'wss://api.dev.viuhealth.com',
  // socket: 'ws://localhost:3001',
  //socket: 'wss://874b-154-160-3-103.ngrok-free.app',
  adobeClientID: '8c0cd670273d451cbc9b351b11d22318',
  folder: 'viuhealth-medical-records-dev',
  patientVisitFormsBucket: 'viuhealth-patient-visit-forms-dev',
  clinicalTrialsBucket: 'viuhealth-clinical-trials-us-east-1-dev',
  scoresBucket: 'viuhealth-scores-us-east-1-dev',
  authorizationsBucket: 'drugviu-patients-authorizations-dev',
  firebase: {
    projectId: 'care-app-a64f7',
    appId: '1:1057554437356:web:f9c8600bc36b0d945381c9',
    storageBucket: 'care-app-a64f7.appspot.com',
    // locationId: 'us-central',
    apiKey: 'AIzaSyBshz86bPhqmcWcRktiohmFhyOCBrpA8JM',
    // authDomain: 'angular-chat-app-a96fc.firebaseapp.com',
    messagingSenderId: '1057554437356',
  },
  serverKey:
    'AAAA9jsoFOw:APA91bEvIMBjwNwq-Uo8YtkhoUUyXIwkvBr-0PtYeGGMWyuG4DslWbwVE8bB5xN0vOT45iW2A-aPyDki0N9Ft5d7JD0vejj_vYaCtSfJNS9V2w4yD1s09hVLNebRTSSLOwGs-Q0BL0Jd',
  dbName: 'devUsers',
};
